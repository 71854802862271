import React from 'react';
import dayjs from "dayjs";
import { toast } from 'react-toastify';
import { IoEnterOutline, IoExitOutline } from "react-icons/io5";

import { getCurrentPosition } from "./location";
import style from '../layout/Header/Profile/Profile.module.scss';

import { SourceApp } from 'types';
import { ERROR_CODES, EVENT_TYPE } from './constants';

type CheckInOutMessageProps = {
  date: string,
  hour: string,
  format: string,
  type: string,
  place: string,
};

function buildEventAction(location: any, isCheckIn: any) {
  return {
    type: isCheckIn ? EVENT_TYPE.CHECK_OUT : EVENT_TYPE.CHECK_IN,
    location,
    timezoneOffset: dayjs().format('Z'),
    sourceApp: SourceApp.WEB,
  };
}

function buildManualEventAction(actionType: any, date: any, time: any, userId: string) {
  return {
    type: actionType,
    location: null,
    dateTime: dayjs(`${date} ${time}`),
    timezoneOffset: dayjs(`${date} ${time}`).format('Z'),
    sourceApp: SourceApp.MANUAL,
    userId
  }
}

type EventErrorMessage = {
  message: string
}

function handleEventCreationError(error: any): EventErrorMessage {
  let errorFormatted;

  if (error.code === ERROR_CODES.TIMEOUT) {
    errorFormatted = {
      message: 'The request is taking too long to respond, please try again or contact the administrator.'
    };
  } else if (error.message === ERROR_CODES.NETWORK) {
    errorFormatted = error;
  } else if (error?.response?.status === ERROR_CODES.NOT_ALLOWED) {
    errorFormatted = {
      message: 'Server unreachable, please try again later.'
    }
  } else {
    errorFormatted = error?.response?.data?.error;
  }

  return errorFormatted;
}

export async function buildCheckInOutEvent(isCheckIn: any) {
  const location = await getCurrentPosition();
  const event = buildEventAction(location, isCheckIn);

  return event
}

export function buildEventInformation(type: string, place: string, createdAt: string): CheckInOutMessageProps {
  return {
    place,
    date: dayjs(createdAt).format('ddd, MMMM Do'),
    hour: dayjs(createdAt).format('h:mm'),
    format: dayjs(createdAt).format('A'),
    type: type === EVENT_TYPE.CHECK_IN ? 'IN' : 'OUT',
  };
}

function generateEventCreatedMessage({ date, hour, format, type, place }: CheckInOutMessageProps) {
  return () => (
    <div>
      <span className={style.headerMessage}>{date}</span>
      <p className={style.bodyMessage}>
        <span className={style.hourMessage}>{hour}</span>
        <span className={style.formatMessage}>{format}</span>
        <span>{type === 'IN' ?
          <IoEnterOutline size="17" className={`${style.icon} ${style.checkInIcon} ${style.toastIconPosition}`} /> :
          <IoExitOutline size="17" className={`${style.icon} ${style.checkOutIcon} ${style.toastIconPosition}`} />}
        </span>
        <span className={`${type === 'IN' ? style.inMessage : style.outMessage}`}>{type}</span>
        <span className={style.placeMessage}>{place}</span>
      </p>
    </div>
  );
}

function getErrorMessage(errorData: any) {
  const error = handleEventCreationError(errorData);

  return (
    <>
      <p>Oops, it was not possible to create the event: <span className={style.errorMessage}>{error?.message}</span></p>
    </>
  );
}

export function eventToastWithPromise(eventPromise: Promise<any>) {
  return toast.promise(
    eventPromise,
    {
      pending: 'Creating the event, please wait...',
      success: {
        render({ data }: any) {
          const { type, place, createdAt } = data;
          const eventInformation = buildEventInformation(type, place, createdAt);
          const Msg = generateEventCreatedMessage(eventInformation);
          return (<Msg />);
        },
      },
      error: {
        render({ data }) {
          return getErrorMessage(data);
        },
        autoClose: 5000,
      }
    },
    {
      autoClose: 4000,
    }
  );
}

export async function checkInOut(
  isCheckIn: boolean,
  createUserEvent: any,
  socketDispatch: any,
  socketActions: any,
  globalDispatch: any,
  globalActions: any,
) {
  const event = await buildCheckInOutEvent(isCheckIn);
  const eventData: any = await eventToastWithPromise(createUserEvent(event));

  socketDispatch(socketActions.SEND_CHECK_IN_OUT(eventData));
  globalDispatch(globalActions.UPDATE_STATE({ lastUserEvent: eventData }));
}

export async function checkInOutManually(
  actionType: boolean,
  date: string,
  time: string,
  userId: string,
  createManualUserEvent: any,
) {
  const event = await buildManualEventAction(actionType, date, time, userId);
  await eventToastWithPromise(createManualUserEvent(event));
}
