import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import style from './Content.module.scss';
import Header from './Header';

import { useAttendanceContext, useGlobalContext, useSidebarContext } from 'contexts';
import { APP_CONTENT, MODAL_MODE, SIDEBAR_CONTENT, USER_ZOOM } from 'utils/constants';
import GroupList from 'mobile/components/GroupList';
import UserList from 'mobile/components/UserList';
import { GroupModal } from 'components/GroupModal';
import { UserService } from 'service';

const userService = new UserService();

const GROUP_MODAL_STATE_DEFAULT = () => ({ mode: '', groupId: '', isOpen: false });
const GROUP_MODAL_STATE_CREATE = () => ({ mode: MODAL_MODE.CREATE, groupId: '', isOpen: true });
const GROUP_MODAL_STATE_EDIT = (groupId: string) => ({ mode: MODAL_MODE.UPDATE, groupId, isOpen: true });
const GROUP_MODAL_STATE_REMOVE = (groupId: string) => ({ mode: MODAL_MODE.REMOVE, groupId, isOpen: true });

type ContentProps = {
  mode: string,
};

export default function Content({ mode }: ContentProps) {
  // Global Context
  const [globalState] = useGlobalContext();
  const { user } = globalState;
  // Sidebar Context
  const [sidebarState, sidebarRequest, actions, dispatch] = useSidebarContext();
  const {
    favoriteGroups,
    customGroups,
    systemGroups,
    groupUsers,
    error,
    customGroupPagination,
    systemGroupPagination,
    usersPagination,
    searchQuery,
    groupsToShow
  } = sidebarState;
  const {
    getUserFavoriteGroups,
    getUserCustomGroups,
    getUserSystemGroups,
    selectGroup,
    selectUser,
    selectPersonalGroup,
    addGroup,
    cloneGroup,
    updateGroup,
    removeGroup,
    updateCustomGroupPagination,
    updateSystemGroupPagination,
    updateGroupsToShow,
    updateUsersPagination,
    updateSearchPaginationGroups,
    updateSearchPaginationUsers,
    getGroupUsers,
  } = sidebarRequest;
  // Attendance Context
  const [attendanceState] = useAttendanceContext();
  const { selectedGroup } = attendanceState;

  const history = useHistory();
  const [content, setContent] = useState(SIDEBAR_CONTENT.GROUP);
  const [toggleContent, setToggleContent] = useState(mode === APP_CONTENT.HOME);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(true);
  const [groupModalState, setGroupModalState] = useState(GROUP_MODAL_STATE_DEFAULT());
  const [searchBarIsActive, setSearchBarIsActive] = useState(false);

  useEffect( () => {
    if (mode === 'home') {
      history.replace('/home');
    }
  }, []);

  async function fetchFavoriteGroups() {
    await getUserFavoriteGroups();
  }

  async function fetchSystemGroups() {
    await getUserSystemGroups();
  }

  async function fetchCustomGroups() {
    await getUserCustomGroups();
  }

  async function fetchUsers() {
    if (sidebarState.selectedGroup._id && content === SIDEBAR_CONTENT.USERS) {
      await getGroupUsers(sidebarState.selectedGroup);
    }
  }

  async function onSelectGroup(newSelectedGroup: any) {
    setLoading(true);
    selectGroup(newSelectedGroup);
    onSearchCancel();
    setContent(SIDEBAR_CONTENT.USERS);
    setSearchBarIsActive(false);
    setLoading(false);
  }

  async function onSelectUser(newSelectedUser: any) {
    await selectUser(newSelectedUser);
    if (newSelectedUser?.location?.coordinates) {
      const { coordinates } = newSelectedUser.location;
      history.replace(`/home/${[...coordinates].reverse().join(',')}/${USER_ZOOM}`);
    } else {
      history.replace('/home');
    }
  }

  async function onPersonalGroupSelected(): Promise<void> {
    setLoading(true);
    await selectPersonalGroup();
    setLoading(false);
    if (mode !== APP_CONTENT.HOME) {
      setContent(SIDEBAR_CONTENT.USERS);
    }
  }

  async function onGoBack(): Promise<void> {
    setContent(SIDEBAR_CONTENT.GROUP);
    updateSearchPaginationGroups({ query: '' });
    history.replace('/home');
  }

  function onCreateGroup(): void {
    setGroupModalState(GROUP_MODAL_STATE_CREATE());
  }

  function onEditGroup(group: any): void {
    setGroupModalState(GROUP_MODAL_STATE_EDIT(group._id));
  }

  function onRemoveGroup(group: any): void {
    setGroupModalState(GROUP_MODAL_STATE_REMOVE(group._id));
  }

  async function onChangeFavorite(group: any) {
    const { isFavorite } = group;
    const newIsFavorite = !isFavorite;
    try {
      let userRequest;
      if (newIsFavorite) {
        userRequest = await userService.addFavoriteGroup(group._id, user._id);
      } else {
        userRequest = await userService.removeFavoriteGroup(group._id, user._id);
      }
      if (userRequest) {
        await userRequest.toAxios();
        dispatch(actions.CHANGE_FAVORITE(group));
        selectGroup({ ...group, isFavorite: newIsFavorite }, sidebarState.groupUsers);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  async function handleCompleteGroupModal(groupModalMode: string, groupData: any, currentUser: any): Promise<void> {
    if (groupModalMode === MODAL_MODE.CREATE) {
      await addGroup(groupData);
    } else if (groupModalMode === MODAL_MODE.UPDATE) {
      await updateGroup(groupData, currentUser, mode === APP_CONTENT.HOME);
    } else if (groupModalMode === MODAL_MODE.REMOVE) {
      await removeGroup(groupData);
      await onGoBack();
    }
    setGroupModalState(GROUP_MODAL_STATE_DEFAULT());
  }

  function loadMoreSystemGroups() {
    updateSystemGroupPagination({ page: systemGroupPagination.page + 1 });
  }

  function loadMoreCustomGroups() {
    updateCustomGroupPagination({ page: customGroupPagination.page + 1 });
  }

  function loadMoreUsers() {
    updateUsersPagination({ page: usersPagination.page + 1 });
  }

  function onSearchQuery(criteria: string) {
    if (content === SIDEBAR_CONTENT.GROUP) {
      updateSearchPaginationGroups({ query: criteria });
    } else if (mode === APP_CONTENT.HOME) {
      updateSearchPaginationUsers({ query: criteria });
    }
    setSearchInput(criteria);
  }

  async function onShowGroups(groupsToShow: any) {
    if (groupsToShow) updateGroupsToShow(groupsToShow);
  }

  function cleanGroupSearch() {
    if (searchQuery) {
      updateSearchPaginationGroups({ query: '' });
    }
  }

  function cleanUserSearch() {
    if (searchQuery) {
      updateSearchPaginationUsers({ query: '' });
    }
  }

  function onSearchCancel() {
    setSearchInput('');
    if (content === SIDEBAR_CONTENT.GROUP) {
      cleanGroupSearch();
    } else if (mode === APP_CONTENT.HOME) {
      cleanUserSearch();
    }
  }

  return (
    <div className={`${style.container} ${toggleContent ? style.collapseContainer : ''}`}>
      <Header
        user={user}
        content={content}
        showToggleIcon={mode === APP_CONTENT.HOME}
        selectedGroup={selectedGroup}
        onToggleContent={() => setToggleContent(!toggleContent)}
        onBackButtonClick={onGoBack}
        onCreateGroup={onCreateGroup}
        onChangeFavorite={onChangeFavorite}
        onCloneGroup={cloneGroup}
        onEditGroup={onEditGroup}
        onRemoveGroup={onRemoveGroup}
        onSearchQueryChanged={onSearchQuery}
        onSearchQueryCanceled={onSearchCancel}
        searchBarIsActive={searchBarIsActive}
        setSearchBarIsActive={setSearchBarIsActive}
      />
      {
        content === SIDEBAR_CONTENT.USERS ? (
          <UserList
            mode={mode}
            searchInput={searchInput}
            loadingGroupUsers={loading}
            groupUsers={groupUsers}
            errorGroupUsers={error}
            onSelectUser={onSelectUser}
            loadMoreUsers={loadMoreUsers}
            requestUsers={fetchUsers}
            usersPagination={{ ...usersPagination, query: searchQuery }}
          />
        ) : (
          <GroupList
            user={user}
            selectedGroup={selectedGroup}
            onClickPersonalGroup={onPersonalGroupSelected}
            favoriteGroups={favoriteGroups}
            customGroups={customGroups}
            systemGroups={systemGroups}
            onClick={onSelectGroup}
            onEditGroup={onEditGroup}
            onCloneGroup={cloneGroup}
            onRemoveGroup={onRemoveGroup}
            onChangeFavorite={onChangeFavorite}
            loadMoreSystemGroups={loadMoreSystemGroups}
            loadMoreCustomGroups={loadMoreCustomGroups}
            requestFavoriteGroups={fetchFavoriteGroups}
            requestSystemGroups={fetchSystemGroups}
            requestCustomGroups={fetchCustomGroups}
            pagination={{ customGroups: customGroupPagination, systemGroups: systemGroupPagination, query: searchQuery }}
            groupsToShow={groupsToShow}
            onShowGroups={onShowGroups}
          />
        )
      }
      <GroupModal
        className={style.groupModal}
        opened={groupModalState.isOpen}
        mode={groupModalState.mode}
        groupId={groupModalState.groupId}
        onComplete={handleCompleteGroupModal}
        onClose={() => setGroupModalState(GROUP_MODAL_STATE_DEFAULT())}
      />
    </div>
  );
}
