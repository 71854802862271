/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { Bounce, ToastContainer } from 'react-toastify';
import { Font } from '@react-pdf/renderer';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes/Routes';

import GlobalContextProvider from 'contexts/global';

const helveticaNeueBold = require('./assets/fonts/HelveticaNeue-Bold.ttf');
const helveticaNeueLight = require('./assets/fonts/HelveticaNeue-Light.ttf');

Font.register({ family: 'HelveticaNeue', fonts: [{ src: helveticaNeueBold, fontStyle: 'normal', fontWeight: 700 }] });

Font.register({ family: 'HelveticaNeueLight', fonts: [{ src: helveticaNeueLight }] });

const App: React.FC = () => (
  <GlobalContextProvider>
    <Routes />
    <ToastContainer
      position="top-right"
      autoClose={3500}
      transition={Bounce}
      draggable={false}
      style={{ marginTop: '10vh' }}
    />
  </GlobalContextProvider>
);

export default App;
