import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoEnterOutline, IoExitOutline } from "react-icons/io5";
import { Menu, Dropdown, Loader } from 'semantic-ui-react';

import { Avatar } from 'components/Avatar';
import style from './Profile.module.scss';

import { useGlobalContext, useSocketContext, useAttendanceContext } from 'contexts';

import { CONTACT_SUPPORT_URL, EVENT_TYPE } from 'utils/constants';
import { checkInOut } from 'utils/checkInCheckOut';

type CheckInOutEventProps = {
  loading: boolean,
  isCheckIn: boolean,
  onClick: () => void,
};

function CheckInOutEvent({ loading, isCheckIn, onClick }: CheckInOutEventProps): JSX.Element {
  return (
    <Dropdown.Item
      className={style.checkInOut}
      onClick={onClick}
      disabled={loading}
    >
      <div className={style.ciCoButton}>
        {isCheckIn ? (
          <IoExitOutline size={20} className={`${style.icon} ${style.checkOutIcon}`} />
        ) : (
          <IoEnterOutline size={20} className={`${style.icon} ${style.checkInIcon}`} />
        )}
        <span className={isCheckIn ? style.checkInLabel : style.checkOutLabel}>
          {isCheckIn ? 'Check Out' : 'Check In'}
        </span>
        {loading && <Loader active inline="centered" size="mini" />}
      </div>
    </Dropdown.Item>
  );
}

export default function Profile(): JSX.Element {
  const [globalState, globalRequest, globalActions, globalDispatch] = useGlobalContext();
  const [, attendanceRequest] = useAttendanceContext();
  const { getLastUserEventById } = attendanceRequest;
  const { user, lastUserEvent } = globalState;
  const { createUserEvent } = globalRequest;
  const [, socketActions, socketDispatch] = useSocketContext();
  const initialState = { admin: false };
  const [data, setData] = useState<any>(initialState);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { isAdmin } = globalRequest;

  const load = useCallback(async () => {
    if (!user._id) return;
    const result = await getLastUserEventById(user._id);
    const admin = await isAdmin();
    setData({ admin });
    globalDispatch(globalActions.UPDATE_STATE({ lastUserEvent: result }));
  }, [globalDispatch, globalActions, getLastUserEventById, user, isAdmin]);

  useEffect(() => {
    load();
  }, [user]); // eslint-disable-line


  async function onCheckInOutHandler() {
    try {
      setLoading(true);
      const isCheckIn = lastUserEvent.type === EVENT_TYPE.CHECK_IN;

      await checkInOut(
        isCheckIn,
        createUserEvent,
        socketDispatch,
        socketActions,
        globalDispatch,
        globalActions
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Menu secondary className={style.profile}>
      <Dropdown
        as={Menu.Item}
        floating
        icon={(
          <Avatar
            src={user?.picture}
            name={user?.preferredUsername}
            size={40}
            isCheckIn={lastUserEvent.type === EVENT_TYPE.CHECK_IN}
          />
        )}
      >
        <Dropdown.Menu direction="left">
          <CheckInOutEvent
            loading={loading}
            isCheckIn={lastUserEvent.type === EVENT_TYPE.CHECK_IN}
            onClick={onCheckInOutHandler}
          />
          { data.admin === true ? (<Dropdown.Item onClick={() => history.push('/settings/notification')}>Settings</Dropdown.Item>) : null}
          <Dropdown.Item onClick={() => window.open(CONTACT_SUPPORT_URL, '_blank')}>Support</Dropdown.Item>
          <Dropdown.Item onClick={() => history.push('/logout')}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
}

Profile.defaultProps = {
  profilePictureSrc: null,
};
