import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Dropdown, Form, Icon, Message, Popup, Button } from 'semantic-ui-react';
import { isEmpty, noop, debounce } from 'lodash';
import L from 'leaflet';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Map, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { toast } from 'react-toastify';
import { MdSettings, MdWeb } from 'react-icons/md';
import { BiMobile } from 'react-icons/bi';
import { SiMicrosoftteams } from 'react-icons/si';
import { RiUserSettingsLine } from "react-icons/ri";

import MarkersList from '../../Marker/MarkersList';

import style from './AttendanceModal.module.scss';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import TimeInputEditable from 'components/TimeInputEditable';
import ManualRecordAccordion from 'components/Attendance/ManualRecordAccordion';
import { useAttendanceContext, useGlobalContext, useSocketContext } from 'contexts';
import { useUser } from 'hooks/useUser';
import { DAY_SECONDS, EVENT_TYPE, DEFAULT_EMPLOYEE_LOCATION, DEFAULT_ZOOM } from 'utils/constants';

import { UserService } from 'service';
import { SourceApp, UsersEventWithAction } from 'types';
import array from 'utils/array';
import { checkInOut, checkInOutManually } from 'utils/checkInCheckOut';
import { isSameDayOrBefore } from 'utils/datetime';

dayjs.extend(utc);

type AttendanceModalProps = {
  opened: boolean;
  userId: string;
  date: string;
  onClose?: any;
  onOpenMarker?: any;
  onDateChange?: any;
};

const userService = new UserService();

const BERMUDA_TRIANGLE_COORDINATES: [number, number] = [
  DEFAULT_EMPLOYEE_LOCATION.LATITUDE,
  DEFAULT_EMPLOYEE_LOCATION.LONGITUDE,
];

const defaultErrorContent = {
  header: '',
  content: '',
};

function clusterCustomIcon(cluster: L.MarkerCluster): any {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'leaflet-marker-icon marker-group',
    iconSize: L.point(45, 45, true),
  });
}

const sourceAppIndicator = (source: SourceApp) => {
  const application = {
    [SourceApp.WEB]: { label: 'Web app', icon: <MdWeb className={style.indicatorIcon} /> },
    [SourceApp.TEAMS]: { label: 'Teams', icon: <SiMicrosoftteams className={style.indicatorIcon} /> },
    [SourceApp.MOBILE]: { label: 'Mobile app', icon: <BiMobile className={style.indicatorIcon} /> },
    [SourceApp.SYSTEM]: { label: 'System', icon: <MdSettings className={style.indicatorIcon} /> },
    [SourceApp.MANUAL]: { label: 'Manual', icon: <RiUserSettingsLine className={style.indicatorIcon} /> },
  };
  return application[source] || { label: 'Web app', icon: <MdWeb className={style.indicatorIcon} /> };
};

export default function AttendanceModal({
  opened,
  userId,
  date,
  onDateChange,
  onClose,
  onOpenMarker,
}: AttendanceModalProps): JSX.Element {
  const [state] = useAttendanceContext();
  const [globalState, globalRequest, globalActions, globalDispatch] = useGlobalContext();
  const { selectedGroup } = state;
  const [attendances, setAttendances] = useState<[UsersEventWithAction] | []>([]);
  const [removedRecordsDueToCheckoutLimit, setRemovedRecordsDueToCheckoutLimit] = useState<boolean>(false);
  const [modalOffset, setModalOffset] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [tzHasBeenChanged, setTzHasBeenChanged] = useState(false);
  const { currentUserIsAdmin, currentUser } = useUser();
  const { createUserEvent, updateUserEvent, createManualUserEvent } = globalRequest;
  const [errorMessage, setErrorMessage] = useState(defaultErrorContent);

  const { lastUserEvent } = globalState;
  const lastEventIsCheckIn = lastUserEvent.type === EVENT_TYPE.CHECK_IN;
  const [, socketActions, socketDispatch] = useSocketContext();

  const fetchGetUserEventsByUser = useCallback(async () => {
    setLoading(true);
    try {
      const startDate = dayjs(date)
        .utcOffset(0)
        .startOf('date')
        .toISOString();
      let tz: string = '';
      if (modalOffset) {
        tz = encodeURIComponent(dayjs().utcOffset(modalOffset).format('Z'));
      }
      const endDate = dayjs(date)
        .utcOffset(0)
        .endOf('date')
        .toISOString();
      const request = await userService.getEventsByUser(
        userId,
        startDate,
        endDate,
        tz,
        { orders: ['createdAt'], limit: 100, skip: 1 },
        selectedGroup?._id
      );
      const response = await request.toAxios();
      setAttendances(response.data?.data ?? []);
      setRemovedRecordsDueToCheckoutLimit(response.data?.removedRecordsDueToCheckoutLimit ?? false);
    } catch (err) {
      toast.error('error getting user events');
    } finally {
      setLoading(false);
    }
  }, [date, modalOffset, selectedGroup, userId]);

  const getCenterPoint = (): [number, number] => {
    const [originPoint] = attendances;
    if (originPoint && originPoint.location && originPoint.location.coordinates) {
      const [
        latitude = DEFAULT_EMPLOYEE_LOCATION.LONGITUDE,
        longitude = DEFAULT_EMPLOYEE_LOCATION.LATITUDE,
      ] = originPoint.location.coordinates;
      return [longitude, latitude];
    }
    return BERMUDA_TRIANGLE_COORDINATES;
  };

  const getUserOffset = (): number => {
    const [firstAttendance] = attendances;
    return firstAttendance
      ? convertHoursToMinutes(firstAttendance?.timezoneOffset)
      : new Date().getTimezoneOffset() * -1;
  };

  useEffect(() => {
    if (opened) {
      fetchGetUserEventsByUser();
      setErrorMessage(defaultErrorContent);
    }
  }, [date, modalOffset, opened]);

  const updateAttendances = (newAttendance: any) => {
    const [, newAttendances] = array.update(
      attendances,
      (event: UsersEventWithAction) => event._id === newAttendance._id,
      { ...newAttendance }
    );
    setAttendances(newAttendances as [UsersEventWithAction]);
  };

  const errorHandling = (error: any): void => {
    if (error.response && error.response.data && error.response.data.error) {
      const { message } = error.response.data.error;
      setErrorMessage({ header: 'Server Error', content: message });
    }
  };

  const updateEvent = async (time: string, attendance: any) => {
    const { createdAt, timezoneOffset, _id, userId: userIdEvent } = attendance;
    const dataToUpdate = { timezoneOffset, createdAt };
    const startDate = dayjs(date).toISOString();
    const endDate = dayjs(date).add(DAY_SECONDS - 1, 'seconds').toISOString();
    const regex = time.match(/(?<hours>0\d|1\d|2[0-3]):(?<minutes>[0-5]\d)/);
    const { hours, minutes } = regex?.groups || { hours: '', minutes: '' };
    const newUtcDate = dayjs(createdAt)
      .utcOffset(modalOffset ?? convertHoursToMinutes(attendance.timezoneOffset))
      .hour(+hours)
      .minute(+minutes)
      .second(0)
      .millisecond(0)
      .toISOString();
    dataToUpdate.createdAt = newUtcDate;
    try {
      const updateActionPromise = updateUserEvent(dataToUpdate, _id, userIdEvent, startDate, endDate);
      const eventUpdated: any = await toast.promise(
        updateActionPromise,
        {
          pending: 'Updating the event',
          success: 'The event was updated successfully',
          error: 'It was not possible to update the event',
        },
        {
          autoClose: 4000,
        }
      );

      updateAttendances({
        _id: eventUpdated._id,
        updatedAt: eventUpdated.updatedAt,
        isEdited: eventUpdated.isEdited,
        editedBy: eventUpdated.editedBy,
        originalCreatedAt: eventUpdated.originalCreatedAt,
        createdAt: eventUpdated.createdAt,
      });
      if (!isEmpty(errorMessage.content)) {
        setErrorMessage(defaultErrorContent);
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const debouncedUpdateEvent = debounce((time: string, attendance: any) => {
    updateEvent(time, attendance);
  }, 800);

  const showTimeCell = (attendance: any) => {
    const { createdAt } = attendance;
    const time = dayjs(createdAt)
      .utcOffset(modalOffset ?? convertHoursToMinutes(attendance.timezoneOffset))
      .format('HH:mm');

    return tzHasBeenChanged ? (
      <TimeInputEditable className={style.time} currentValue={time} format="12" onInputChange={noop} readonly={true} />
    ) : (
      <TimeInputEditable
        className={style.time}
        currentValue={time}
        format="12"
        onInputChange={(newTime: string) => debouncedUpdateEvent(newTime, attendance)}
        readonly={!currentUserIsAdmin}
      />
    );
  };

  const showIndicators = (attendance: any) => {
    const { isEdited, sourceApp, editedBy, originalCreatedAt, createdAt, timezoneOffset, createdBy } = attendance;
    const source = sourceAppIndicator(sourceApp);
    let textEditIcon = <></>;
    let textManualRecord = <></>;
    let isManualRecord = sourceApp === SourceApp.MANUAL;

    if (isEdited) {
      const offset = convertHoursToMinutes(timezoneOffset);
      const originalTime = dayjs(originalCreatedAt).utcOffset(offset).format('hh:mm A');
      const newTime = dayjs(createdAt).utcOffset(offset).format('hh:mm A');
      textEditIcon = (
        <p>
          Original: {originalTime}<br />
          Edited to: {newTime}<br />
          Edited by: {editedBy?.name}<br />
        </p>
      );
    }

    if (isManualRecord) {
      textManualRecord = (
        <p style={{ textAlign: 'center' }}>
          Manual Record <br />
          { createdBy ? `Created by: ${createdBy?.name}` : null }
        </p>
      );
    }

    return (
      <>
        {isManualRecord ? (
          <Popup content={textManualRecord} position="top center" size="mini" trigger={source.icon} />
        ) : (
          <Popup content={source.label} position="top center" size="mini" trigger={source.icon} />
        )}

        {isEdited && (
          <Popup
            content={textEditIcon}
            position="top center"
            size="mini"
            trigger={<Icon name="pencil" size="small" className={style.indicatorIcon} />}
          />
        )}
      </>
    );
  };

  const showTimeZone = () => {
    const localOffSet = new Date().getTimezoneOffset() * -1;
    const userOffSet = getUserOffset();
    const userTime = dayjs(new Date()).utcOffset(userOffSet, false).format(`hh:mm A Z`);
    const localTime = dayjs(new Date()).utcOffset(localOffSet, false).format('hh:mm A Z');
    let result = Math.abs(userOffSet - localOffSet);
    let hoursResult = Math.trunc(result / 60);
    let minutesResult = result % 60;
    let message;
    if (userOffSet === localOffSet) {
      message = ` ${userTime} - Same time zone as you`;
    } else if (localOffSet > userOffSet) {
      if (modalOffset === localOffSet) {
        message = ` ${localTime} - Your time zone is ${hoursResult > 0 ? `${hoursResult} h` : ''} ${minutesResult > 0 ? `${minutesResult} m` : ''} ahead`;
      } else {
        message = ` ${userTime} - User's timezone is ${hoursResult > 0 ? `${hoursResult} h` : ''} ${minutesResult > 0 ? `${minutesResult} m` : ''} behind yours`;
      }
    } else {
      if (modalOffset === localOffSet) {
        message = ` ${localTime} - Your time zone is ${hoursResult > 0 ? `${hoursResult} h` : ''} ${minutesResult > 0 ? `${minutesResult} m` : ''} behind`;
      } else {
        message = ` ${userTime} - User's timezone is ${hoursResult > 0 ? `${hoursResult} h` : ''} ${minutesResult > 0 ? `${minutesResult} m` : ''} ahead yours`;
      }
    }
    return (
    <>
      <Icon name="clock outline" className={style.clockTz} />
      {message}
      {userOffSet !== localOffSet && (
        <Dropdown direction="left" className={style.tzSelector} icon={<Icon name="ellipsis vertical" />}>
          <Dropdown.Menu>
            <Dropdown.Item
              text={`Show records on UTC ${dayjs().utcOffset(userOffSet).format('Z')} (User's TZ)`}
              onClick={() => {
                setTzHasBeenChanged(true);
                setModalOffset(userOffSet);
                setTimeout(() => setTzHasBeenChanged(false), 1000);
              }}
            />
            <Dropdown.Item
              text={`Show records on UTC ${dayjs().utcOffset(localOffSet).format('Z')} (Mine TZ)`}
              onClick={() => {
                setTzHasBeenChanged(true);
                setModalOffset(localOffSet);
                setTimeout(() => setTzHasBeenChanged(false), 1000);
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
    );
  };

  function convertHoursToMinutes(timezone: string): number {
    const [hoursInt, minutesInt]: number[] = timezone.split(':').map(Number);
    const timeZoneMinutes = hoursInt < 0 ? (minutesInt * -1) : minutesInt;
    const valueTzInMinutes = (hoursInt * 60) + timeZoneMinutes;
    return valueTzInMinutes;
  }

  async function clickCICOButton() {
    try {
      setLoading(true);
      await checkInOut(lastEventIsCheckIn, createUserEvent, socketDispatch, socketActions, globalDispatch, globalActions);
    } finally {
      setLoading(false);
    }
  }

  async function saveManualRecord(record: any) {
    try {
      const { action, time, date } = record;
      await checkInOutManually(
        action,
        date,
        time,
        userId,
        createManualUserEvent,
      );
    } finally {
      fetchGetUserEventsByUser();
    }
  }

  return (
    <Modal opened={opened} title="Attendance Records" onClose={onClose}>
      <div className={style.container}>
        {attendances.length ?
          <div className={style.tzContainer}>
            <div>
              {showTimeZone()}
            </div>
          </div> : ''
        }
        <div className={style.header}>
          <div className={style.leftArrow} onClick={() => {
            onDateChange(dayjs(date).subtract(1, 'day').format('YYYY-MM-DD'));
          }}>
            <Icon size='large' name='chevron left'/>
          </div>
          <div className={style.timeHeader}>
            <Divider horizontal className='divider'>
              <div className={style.time}>{dayjs(date).format('ddd, MMMM Do')}</div>
            </Divider>
          </div>
          <div className={style.rightArrow} onClick={() => {
            onDateChange(dayjs(date).add(1, 'day').format('YYYY-MM-DD'));
          }}>
            <Icon size='large' name='chevron right'/>
          </div>
        </div>
        {currentUserIsAdmin && isSameDayOrBefore(date) && (
          <ManualRecordAccordion currentDate={date} onSubmit={saveManualRecord} />
        )}
        {
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <Loader active inline="centered" size="medium" />
          ) : attendances.length ? (
            <>
              {errorMessage.header !== '' && errorMessage.content !== '' && (
                <Message id={style.attendanceErrorMessage} size="tiny" error header={errorMessage.header} content={errorMessage.content} />
              )}
              <Form className={style.attendanceList}>
                {attendances.map((attendance: any) => (
                  <div key={attendance._id} className={style.attendanceItem}>
                    <div className={style.timeCell}>{showTimeCell(attendance)}</div>
                    <div className={attendance.type === EVENT_TYPE.CHECK_IN ? style.checkInCell : style.checkOutCell}>
                      {attendance.type === EVENT_TYPE.CHECK_IN ? 'IN' : 'OUT'}
                    </div>
                    <div className={style.placeCell}>{attendance.place}</div>
                    <div className={style.indicatorCell}>{showIndicators(attendance)}</div>
                  </div>
                ))}
              </Form>
            </>
          ) : (
            removedRecordsDueToCheckoutLimit ?
            <Message>
              The automatic daily checkout removed the records because the user had an open check-in with total hours exceeding the 15-hour limit.
            </Message> :
            <Message>There are no attendance records for this date</Message>
          )
        }
        {attendances.length ? (
          <Map
            className={`markercluster-map ${style.markerclusterMap}`}
            center={getCenterPoint()}
            zoom={getCenterPoint() === BERMUDA_TRIANGLE_COORDINATES ? DEFAULT_ZOOM : 14}
            maxZoom={18}
            tap={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors. Search by <a href="https://locationiq.com">LocationIQ.com</a>'
            />
            <MarkerClusterGroup iconCreateFunction={clusterCustomIcon}>
              <MarkersList userEvents={[...attendances].reverse()} onOpen={onOpenMarker} markerType="attendance" />
            </MarkerClusterGroup>
          </Map>
        ) : <></>}
        { dayjs(date).isSame(dayjs(), 'day') && userId === currentUser?.profile.sub && !removedRecordsDueToCheckoutLimit ?
        (
          <div className={`${style.buttonDiv}`}>
            <Button
              className={`${lastEventIsCheckIn ? `${style.checkOutButton}` : `${style.checkInButton}`} ${style.button}`}
              onClick={clickCICOButton}
              disabled={loading}
            >
              <div
                className={`${style.text}`}
                style={{ height: '100%' }}
              >
                {lastEventIsCheckIn ? 'Check out' : 'Check in'}
              </div>
            </Button>
          </div>
        ) : ''}
      </div>
    </Modal>
  );
}

AttendanceModal.defaultProps = {
  onClose: noop,
  onOpenMarker: noop,
};
