import axios from 'axios';

import { WithAuthResponse } from '../types';

export class RequestOptions {
  options: WithAuthResponse;

  constructor(options: WithAuthResponse) {
    this.options = { timeout: 10000, ...options };
  }

  set(options: WithAuthResponse): void {
    this.options = Object.assign(this.options, options);
  }

  async toAxios(): Promise<any> {
    return axios(this.options);
  }

  toObject(): WithAuthResponse {
    return this.options;
  }
}
